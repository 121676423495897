
 import * as React from "react";
 import { graphql, useStaticQuery, Link } from "gatsby";
 import dayjs from "dayjs";
 
 import Layout from "../../../components/layout";
 import SEO from "../../../components/seo";
 import BlogPost from '../../../components/blog-post'
 
 import banner from "../../../../static/banner.png";
 
 import "../../../styles/blog.css";
 
 const WORDS_PER_MINUTE = 265;
 
 const Author = ({ data, path }) => {
   const { allMarkdownRemark, markdownRemark, authorsList } = data;
   const filteredRemark = allMarkdownRemark.nodes.filter(el => el.frontmatter.author === markdownRemark.frontmatter.author)

   const sortDates = (a, b) => {
    const first = a.frontmatter.date;
    const second = b.frontmatter.date;

    if (dayjs(first).diff(second) > 0) {
      return -1;
    }
    if (dayjs(first).diff(second) < 0) {
      return 1;
    }
    return 0;
  };

  const sortedRemark = filteredRemark.sort(sortDates);
 
   return (
    <>
      <SEO title={`Posts by ${markdownRemark.frontmatter.author}`} />
      <Layout>
        <main className="max-w-container flex flex-col space-y-10 py-20 px-10 lg:px-0 mx-auto">
          <h2 className="mb-8 w-full text-center">
            <span className="text-red">{markdownRemark.frontmatter.author}</span> Posts
          </h2>
          <div className="lg:px-20">
            {sortedRemark.map((node) => <BlogPost data={node} authorsList={authorsList.nodes} />)}
          </div>
        </main>
      </Layout>
    </>
   );
 };
 
 export const pageQuery = graphql`
   query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        author
      }
    }
    allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/(post)/" } }
      ) {
        nodes {
          id
          wordCount {
            words
          }
          frontmatter {
            title
            author
            date
            slug
            tags
            description
            featured_image
          }
        }
      }
     authorsList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/(authors)/" } }
    ) {
      nodes {
        frontmatter {
          name
          avatar
        }
      }
    }
   }
 `;
 
 export default Author;
 